import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: '/projectManagementScreen'
  },
  {
    path: '/projectManagementScreen',
    component: () => import("@/views/projectManagementScreen"),
  }
];
export const router = new Router({
  routes,
});
